import React from "react";
import CardBudget from "@components/Budget/CardBudget/CardBudget";
import { ButtonPrimary } from "@components/Reusables";
import { useStyles } from "./Step3.style";
import { averageSpending, averageVariable, fixedCost } from "@images";

interface step3props {
  onClick: () => void;
  data: any;
  fixedExpenses: number;
  variableExpenses: number;
}

const Step3 = ({
  onClick,
  data,
  fixedExpenses,
  variableExpenses,
}: step3props) => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <div className={classes.hight}>
        <span>
          En base a la información que entregaste, tu gasto promedio es:
        </span>
        <div className="block">
          <span>$ {variableExpenses + fixedExpenses}</span>
          <img src={averageSpending} />
        </div>
        <div className="blockPurple"></div>
      </div>
      <div className={classes.cardContainer}>
        <CardBudget
          noBottomSection
          title="Gasto fijo"
          value={fixedExpenses}
          image={fixedCost}
        />
        <CardBudget
          noBottomSection
          title="Gasto variable"
          value={variableExpenses}
          image={averageVariable}
        />
      </div>
      <div className={classes.buttonBottom}>
        <ButtonPrimary
          secondaryVersion
          text="Terminar misión"
          onClick={() => onClick()}
        />
      </div>
      {/*<div className={classes.txtBottom}>
        Si quieres analizar tus gastos de forma automática e inteligente prueba
        nuestra herramienta para <strong> sincronizar cuentas</strong>
      </div>*/}
    </section>
  );
};

export default Step3;
