import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "Montserrat",
    color: theme.palette.text.primary,
    width: 1100,
    margin: "0 auto",
    [theme.breakpoints.down(1270)]: {
      width: 375,
      margin: "0 auto",
    },
    "& >div": {},
    "& .container": {
      display: "flex",
      flexWrap: "wrap",
      maxWidth: 1000,
      "& >div": {
        width: 300,
        marginRight: "2rem",
      },
    },
  },
  hight: {
    background: "8352fd",
    display: "flex",
    marginLeft: 20,
    marginTop: 30,
    justifyContent: "space-between",
    position: "relative",
    [theme.breakpoints.down(1270)]: {
      display: "grid",
      marginTop: "4rem",
    },
    "& .blockPurple": {
      background: "#8352fd",
      width: "100%",
      height: 120,
      position: "absolute",
      bottom: 10,
      zIndex: 0,
      borderRadius: 30,
      [theme.breakpoints.down(1270)]: {
        height: 300,
        width: 300,
      },
    },
    "& >span": {
      display: "block",
      width: 725,
      fontSize: 24,
      top: 0,
      fontWeight: "bold",
      position: "absolute",
      [theme.breakpoints.down(1270)]: {
        fontSize: 14,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
        position: "relative",
        marginBottom: "4rem",
      },
    },
    "& .block": {
      display: "flex",
      alignItems: "end",
      width: "100%",
      paddingTop: 0,
      justifyContent: "space-between",
      zIndex: 5,
      [theme.breakpoints.down(1270)]: {
        display: "grid",
      },
      "& >span": {
        marginBottom: 45,
        fontSize: 36,
        fontWeight: "bold",
        marginLeft: 110,
        color: "white",
        [theme.breakpoints.down(1270)]: {
          margin: "0 auto",
        },
      },
      "& img": {
        width: 300,
      },
    },
  },
  cardContainer: {
    display: "flex",
    width: "80%",
    margin: "0 auto",
    justifyContent: "space-between",
    [theme.breakpoints.down(1270)]: {
      display: "grid",
    },
    "& button": {
      [theme.breakpoints.down(1270)]: {
        justifyContent: "center",
      },
    },
  },
  buttonBottom: {
    width: 200,
    margin: "30px 0 0 auto",
    [theme.breakpoints.down(1270)]: {
      width: 375,
      display: "flex",
      justifyContent: "center",
    },
  },
  txtBottom: {
    width: 480,
    marginTop: 20,
    fontSize: 14,
    [theme.breakpoints.down(1270)]: {
      width: 375,
      margin: "30px auto",
      textAlign: "center",
    },
  },
}));
