import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    fontFamily: "Montserrat",
    "& .subtitle": {
      fontSize: 24,
      fontWeight: "bold",
      margin: "0 690px 10px 68px",
    },
    "& .subsubtitle": {
      margin: "30px auto",
      width: 600,
      [theme.breakpoints.down(1270)]: {
        width: "375px!important",
      },
    },
  },
  subtitle: {
    width: "100%!important",
  },
  containerCente: {
    width: 1100,
    margin: "0 auto",
  },
}));
