import React, { useState } from "react";
import { navigate, PageProps } from "gatsby";
import { SEO } from "@components";
import { LayoutBildRegistration } from "@components/Structural/RegistrationStep1";
import Step1 from "@components/Budget/Mision3/Step1/Step1";
import BudgetMission3Subtitle from "@components/Budget/Mision3/BudgetMission3Subtitle/BudgetMission3Subtitle";
import Step2 from "@components/Budget/Mision3/Step2/Step2";
import Step3 from "@components/Budget/Mision3/Step3/Step3";
import { useMediaQuery } from "@material-ui/core";
import { LayoutBudget } from "@components/Structural/Budgets/Layout/Layout";
import { Route } from "@interfaces";
import { useAuth } from "@hooks";
const SaveByRokinPage: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });

  const [isPage, setPage] = useState(0);
  const isMobile = useMediaQuery("(min-width: 1270px)");

  return (
    <>
      <SEO />
      <LayoutBudget
        title="Calculadora de presupuesto"
        subtitle={"Paso 3: tus gastos fijos"}
        subtitleMobile={
          isPage === 0
            ? "Paso 3/6: tus gastos fijos"
            : isPage === 1
            ? "Paso 4: tus gastos variables"
            : "Paso 5: cálculo automático de tu presupuesto"
        }
        onTurnBackClick={() =>
          isPage === 0
            ? navigate(Route.rokipedia)
            : isPage === 1
            ? setPage(0)
            : setPage(1)
        }
      >
        {isPage === 0 ? (
          <>
            <BudgetMission3Subtitle>
              Los gastos fijos son gastos básicos que debes pagar todos los
              meses. Algunos ejemplos son la luz, el arriendo y el agua.
            </BudgetMission3Subtitle>
            <Step1
              onClick={() => {
                navigate(Route.tools4);
              }}
            />
          </>
        ) : isPage === 1 ? (
          <>
            <BudgetMission3Subtitle>
              Los gastos fijos son gastos básicos que debes pagar todos los
              meses. Algunos ejemplos son la luz, el arriendo y el agua.
            </BudgetMission3Subtitle>
            <Step2
              onClick={() => {
                setPage(2);
              }}
            />
          </>
        ) : (
          <>
            <Step3 onClick={() => {}} />
          </>
        )}
      </LayoutBudget>
    </>
  );
};

export default SaveByRokinPage;
