import React from "react";
import { useStyles } from "./BudgetMission3Subtitle.style";

interface BudgetMission3SubtitleProps {
  title?: string;
  children: React.ReactNode;
}

const BudgetMission3Subtitle = ({
  title,
  children,
}: BudgetMission3SubtitleProps) => {
  const classes = useStyles();
  return (
    <section
      className={`${classes.container} ${!title && classes.containerCente}`}
    >
      <div className="subtitle">{title}</div>
      <div className={`subsubtitle ${!title && classes.subtitle}`}>
        <div>{children}</div>
      </div>
    </section>
  );
};

export default BudgetMission3Subtitle;
